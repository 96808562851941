/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import "../intl.js";
import { deprecatedFunction as t } from "../core/deprecate.js";
import { byId as r } from "../core/domUtils.js";
import s from "../core/Evented.js";
import { isEventedOrEventTarget as o, on as i } from "../core/events.js";
import "../core/has.js";
import { clone as n } from "../core/lang.js";
import a from "../core/Logger.js";
import { destroyMaybe as d } from "../core/maybe.js";
import { EsriPromiseMixin as l } from "../core/Promise.js";
import { debounce as c, throwIfNotAbortError as p } from "../core/promiseUtils.js";
import { watch as h, when as u, syncAndInitial as m } from "../core/reactiveUtils.js";
import { generateUUID as g } from "../core/uuid.js";
import { property as v } from "../core/accessorSupport/decorators/property.js";
import { cast as y } from "../core/accessorSupport/decorators/cast.js";
import { subclass as f } from "../core/accessorSupport/decorators/subclass.js";
import { runTracked as _ } from "../core/accessorSupport/tracking.js";
import { SimpleTrackingTarget as j } from "../core/accessorSupport/tracking/SimpleTrackingTarget.js";
import { createAdvancedProjector as b } from "../libs/maquette-advanced-projector/projector.js";
import { commitAssetPath as w } from "./support/componentsUtils.js";
import { isWidgetConstructor as S, processWidgets as R } from "./support/jsxWidgetSupport.js";
import { widgetTestDataSymbol as P, widgetSymbol as k } from "./support/symbols.js";
import { registerLoading as C } from "./support/tests.js";
import { getVNodeCache as E, setVNodeCache as L, deleteVNodeCache as F, clearVNodeCache as T } from "./support/vnodeCache.js";
import { classes as I } from "./support/widgetUtils.js";
import { onLocaleChange as H } from "../intl/locale.js";
import { fetchMessageBundle as N } from "../intl/messages.js";
var $;
const U = "esri.widgets.Widget";
let z = 0;
function W(e, t) {
  const r = Object.prototype.hasOwnProperty;
  for (const s in t) r.call(t, s) && r.call(e, s) && (null != e[s] && null != t[s] && "object" == typeof e[s] && "object" == typeof t[s] ? W(e[s], t[s]) : e[s] = t[s]);
  return e;
}
const x = b({
  postProcessProjectionOptions(e) {
    const t = e.eventHandlerInterceptor,
      r = /capture$/i;
    e.eventHandlerInterceptor = (e, s, o, i) => {
      const n = t?.(e, s, o, i),
        a = r.test(e);
      if (!((e = e.replace(r, "")).toLowerCase() in o) || a) {
        const t = e[2].toLowerCase() + e.slice(3),
          r = e => n?.call(o, e);
        o.addEventListener(t, r, a);
        const s = () => o.removeEventListener(t, r, a),
          d = i.afterRemoved;
        i.afterRemoved = e => {
          d?.(e), s();
        };
      }
      return n;
    };
  },
  handleInterceptedEvent(e, t, r, s) {
    const {
        eventPhase: o,
        type: i
      } = s,
      n = o === Event.CAPTURING_PHASE;
    let a = `on${i}${n ? "capture" : ""}`;
    const d = t.properties;
    (d && a in d || (a = `on${i[0].toUpperCase()}${i.slice(1)}${n ? "Capture" : ""}`, d && a in d)) && (T(), e.scheduleRender(), d[a].call(d.bind || r, s));
  }
});
let A = !1,
  O = class extends l(s.EventedAccessor) {
    constructor(e, t) {
      super(e, t), this._attached = !1, this._projector = x, this._readyForTrueRender = !1, this.key = this, this.autoRenderingEnabled = !0, this._loadLocale = c(async () => {
        if (this._messageBundleProps?.length) {
          const e = await Promise.allSettled(this._messageBundleProps.map(async ({
            bundlePath: e,
            propertyName: t
          }) => {
            if (this.destroyed) return;
            let r = await N(e);
            this.uiStrings && Object.keys(this.uiStrings) && (r = W(n(r), this.uiStrings)), this[t] = r;
          }));
          if (this.destroyed) return;
          for (const t of e) "rejected" === t.status && a.getLogger(this).error("widget-intl:locale-error", this.declaredClass, t.reason);
        }
        await this.loadLocale();
      }), w();
      const r = "esri-widget-uid-" + g(),
        s = this.render.bind(this);
      this._trackingTarget = new j(() => {
        this.autoRenderingEnabled && this.scheduleRender();
      });
      const o = () => ({
          vnodeSelector: "div",
          properties: {
            key: `${r}-hidden`,
            class: "",
            styles: {
              display: "none"
            }
          },
          domNode: null,
          children: void 0,
          text: void 0
        }),
        i = () => {
          if (!this._readyForTrueRender || this.destroyed) return null;
          const e = s() ?? o(),
            t = e.properties ??= {};
          if (t.key ??= r, S(e.vnodeSelector)) {
            if (!this.visible) return o();
          } else this.visible ? t.styles || (t.styles = {}) : (t.class = "", t.styles = {
            display: "none"
          }), t.styles.display ??= "";
          let i = 0;
          return e.children?.forEach(e => {
            S(e.vnodeSelector) || (e.properties ??= {}, e.properties.key ??= `${this.id}--${i++}`);
          }), R(this, e);
        };
      this.render = () => {
        if (A) return i();
        let e = E(this) ?? null;
        if (e) return e;
        this._trackingTarget.clear(), A = !0;
        try {
          e = _(this._trackingTarget, i);
        } catch (t) {
          throw a.getLogger(this).error(t), t;
        } finally {
          A = !1;
        }
        return e && L(this, e), e;
      };
      const d = this.beforeFirstRender();
      d ? this._resourcesFetch = d.then(() => {
        this._readyForTrueRender = !0, this._postInitialize();
      }) : (this._resourcesFetch = Promise.resolve().then(() => {
        this._postInitialize();
      }), this._readyForTrueRender = !0), this.addResolvingPromise(this._resourcesFetch), C(this._resourcesFetch);
    }
    normalizeCtorArgs(e, t) {
      const r = {
        ...e
      };
      return t && (r.container = t), r;
    }
    postInitialize() {}
    beforeFirstRender() {
      const e = this.loadDependencies();
      return this._messageBundleProps?.length || e ? Promise.all([e, this._loadLocale()]).then(() => {}).catch(p) : null;
    }
    loadDependencies() {
      return null;
    }
    loadLocale() {
      return null;
    }
    destroy() {
      this.destroyed || (d(this._trackingTarget), d(this.viewModel), this._detach(this.container), this._set("container", null), this._emitter.clear(), this.render = () => null, this._projector = null, F(this));
    }
    set container(e) {
      this._get("container") || this._set("container", e);
    }
    castContainer(e) {
      return r(e);
    }
    get domNode() {
      return this.container;
    }
    set domNode(e) {
      this.container = e;
    }
    get icon() {
      return null;
    }
    set icon(e) {
      this._overrideIfSome("icon", e);
    }
    get id() {
      return this._get("id") || this.container?.id || Date.now().toString(16) + "-widget-" + z++;
    }
    set id(e) {
      e && this._set("id", e);
    }
    get label() {
      return this.declaredClass.split(".").pop();
    }
    set label(e) {
      this._overrideIfSome("label", e);
    }
    get renderable() {
      return this._resourcesFetch;
    }
    get visible() {
      return this._get("visible");
    }
    set visible(e) {
      this._set("visible", e);
    }
    get [($ = k, P)]() {
      return {
        projector: this._projector
      };
    }
    render() {
      throw new Error("not implemented");
    }
    scheduleRender() {
      this.destroyed || (F(this), this._projector.scheduleRender());
    }
    own(e) {
      t(a.getLogger(this), "`Widget.own()` is deprecated in favor of 'Widget.addHandles()'", {
        replacement: "Widget.addHandles()",
        version: "4.28"
      }), this.addHandles(e);
    }
    classes(...e) {
      return I.apply(this, e);
    }
    renderNow() {
      F(this), this._projector.renderNow();
    }
    _postInitialize() {
      if (this.destroyed) return;
      this.scheduleRender(), this._delegatedEventNames?.length && this.addHandles(h(() => this.viewModel, (e, t) => {
        t && this.removeHandles("delegated-events"), e && o(e) && this.addHandles(this._delegatedEventNames.map(t => i(e, t, e => {
          this.emit(t, e);
        })), "delegated-events");
      }, m)), this.postInitialize();
      const e = async () => {
        await this._loadLocale().catch(p), this.scheduleRender();
      };
      this.addHandles([H(e), h(() => this.uiStrings, e), u(() => this.container, e => {
        this.destroyed || this._attach(e);
      }, {
        initial: !0,
        once: !0
      })]);
    }
    _attach(e) {
      e && (this._projector.merge(e, this.render), this._attached = !0);
    }
    _detach(e) {
      this._attached && (this._projector.detach(this.render), this._attached = !1), e?.parentNode?.removeChild(e);
    }
  };
O[$] = !0, O.vnodeSelector = "div", e([v()], O.prototype, "_readyForTrueRender", void 0), e([v({
  value: null
})], O.prototype, "container", null), e([y("container")], O.prototype, "castContainer", null), e([v()], O.prototype, "icon", null), e([v()], O.prototype, "id", null), e([v()], O.prototype, "label", null), e([v()], O.prototype, "renderable", null), e([v()], O.prototype, "uiStrings", void 0), e([v()], O.prototype, "viewModel", void 0), e([v({
  value: !0
})], O.prototype, "visible", null), e([v()], O.prototype, "key", void 0), e([v()], O.prototype, "children", void 0), e([v()], O.prototype, "afterCreate", void 0), e([v()], O.prototype, "afterUpdate", void 0), e([v()], O.prototype, "afterRemoved", void 0), O = e([f(U)], O);
const B = O;
export { B as default };