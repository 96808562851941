/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const e = {
    namespace: void 0,
    performanceLogger: () => {},
    eventHandlerInterceptor: void 0,
    styleApplyer: (e, r, t) => {
      "-" === r.charAt(0) ? e.style.setProperty(r, t) : e.style[r] = t;
    }
  },
  r = r => ({
    ...e,
    ...r
  });
export { r as applyDefaultProjectionOptions };