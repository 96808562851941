/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { dom as e } from "../maquette/dom.js";
import "../maquette/projector.js";
import { defaultAdvancedProjectorOptions as r } from "./advanced-projector-options.js";
import { applyDefaultProjectionOptions as t } from "./utils.js";
const o = (e, r) => {
    const t = [];
    for (; e && e !== r;) t.push(e), e = e.parentNode;
    return t;
  },
  n = (e, r) => e.find(r),
  d = (e, r, t = !1) => {
    let o = e;
    return r.forEach((e, d) => {
      const s = o?.children ? n(o.children, r => r.domNode === e) : void 0;
      t && !s && d !== r.length - 1 || (o = s);
    }), o;
  },
  s = n => {
    let s;
    const c = {
        ...r,
        ...n
      },
      i = t(c),
      a = i.performanceLogger;
    let m,
      p = !0,
      l = !1;
    const f = [],
      u = [],
      h = (e, r, t) => {
        let n;
        i.eventHandlerInterceptor = (e, r, t, i) => function (e) {
          let r;
          a("domEvent", e);
          const t = o(e.currentTarget, n.domNode),
            i = t.some(e => customElements.get(e?.tagName?.toLowerCase()));
          if (e.eventPhase === Event.CAPTURING_PHASE || !i) t.reverse(), r = d(n.getLastRender(), t);else {
            const t = e.composedPath(),
              o = t.slice(t.indexOf(e.currentTarget), t.indexOf(n.domNode)).reverse();
            r = d(n.getLastRender(), o, !0);
          }
          let m;
          return r && (m = c.handleInterceptedEvent(s, r, this, e)), a("domEventProcessed", e), m;
        }, c.postProcessProjectionOptions?.(i);
        const m = t();
        n = e(r, m, i), i.eventHandlerInterceptor = void 0, f.push(n), u.push(t), c.afterFirstVNodeRendered && c.afterFirstVNodeRendered(n, m);
      };
    let v = () => {
      if (m = void 0, p) {
        p = !1, a("renderStart", void 0);
        for (let r = 0; r < f.length; r++) {
          const t = u[r]();
          a("rendered", void 0);
          try {
            f[r].update(t);
          } catch (e) {
            console.error(e);
          }
          a("patched", void 0);
        }
        a("renderDone", void 0), p = !0;
      }
    };
    return c.modifyDoRenderImplementation && (v = c.modifyDoRenderImplementation(v, f, u)), s = {
      renderNow: v,
      scheduleRender: () => {
        m || l || (m = requestAnimationFrame(v));
      },
      stop: () => {
        m && (cancelAnimationFrame(m), m = void 0), l = !0;
      },
      resume: () => {
        l = !1, p = !0, s.scheduleRender();
      },
      append: (r, t) => {
        h(e.append, r, t);
      },
      insertBefore: (r, t) => {
        h(e.insertBefore, r, t);
      },
      merge: (r, t) => {
        h(e.merge, r, t);
      },
      replace: (r, t) => {
        h(e.replace, r, t);
      },
      detach: e => {
        for (let r = 0; r < u.length; r++) if (u[r] === e) return u.splice(r, 1), f.splice(r, 1)[0];
        throw new Error("renderFunction was not found");
      }
    }, s;
  };
export { s as createAdvancedProjector };