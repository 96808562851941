/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n(n) {
  return "string" == typeof n ? document.getElementById(n) : n ?? null;
}
function t(n, t) {
  for (;;) {
    const e = n.firstChild;
    if (!e) break;
    t.appendChild(e);
  }
}
export { n as byId, t as reparent };